import '../scss/styles.scss';
import moment from 'moment';

(function ($) {
    const body = $('body');
    const hightContrastButtton = $('#dark-theme-button');
    const changeFontButtons = $('button.aria-font');

    if ($('html').attr('lang') === 'pl-PL') {
        moment.lang('pl');
    }
    const setTheme = (theme) => {
        body.addClass(theme);
        localStorage.setItem('theme', theme)
    }

    const removeTheme = () => {
        body.removeClass('dark-theme');
        localStorage.removeItem('theme')
    }

    hightContrastButtton.on('click', (item) => {
        let theme = `${item.currentTarget.attributes.id.value.split('-')[0]}-theme`;
        (localStorage.getItem('theme')) ? removeTheme(): setTheme(theme);
    });

    (localStorage.getItem('theme')) ? setTheme(localStorage.getItem('theme')): false;

    changeFontButtons.on('click', (e) => {

        const _html = $('html').removeAttr('style');

        switch (e.currentTarget.id) {
            case 'font-bigger':
                _html.css("fontSize", "75%");

                break;
            case 'font-smaller':
                _html.css("fontSize", "50%");

                break;
            case 'font-normal':
                _html.css("fontSize", "62.5%");

                break;
            default:
        }
    });

    $('#um-additional-section').detach().appendTo('.site-logo')

    const menu = $('.slideout-menu > .menu-item > a');

    menu.on('click', () => {

        $.each(menu, function () {

            const _parent = $(this).parent();

            if (_parent.length > 0) {

                _parent.removeClass('sfHover')
                    .find('ul.sub-menu')
                    .removeClass('toggled-on')
                    .find('ul.sub-menu').parent()
                    .removeClass('sfHover');
            }
        })
    });

})(jQuery);


(function ($) {
    /**
     * Change accordion elements to indexed + fired up event click
     */
    const colection_accordion_bauttons = $('.wp-block-ub-content-toggle .wp-block-ub-content-toggle-accordion-title-wrap');
    colection_accordion_bauttons
        .each(function () {
            $(this).attr('tabindex', '0');
        })
        .on('keyup', function (e) {
            e.stopPropagation();
            if (e.which == 13) $(this).click();
        });

    /**
     * Add tabindex for selected elements
     */
    $('.add_tabindex, form .sf-field-taxonomy-job_offers_category  label.sf-label-radio').each(function () {
        $(this).attr('tabindex', '0');
    });

    $(document).on('focusin', '.main-navigation span.dropdown-menu-toggle', function (e) {
        const _this = $(this);
        const text = _this.data("title");

        if (text) {
            if (_this.find('.sr-only').length == 0) $(`<span class='sr-only'>${text}</span>`).appendTo(_this);
        }

    });

    $(document).on('focusout', '.main-navigation span.dropdown-menu-toggle', function () {
        $(this).empty();
    })

})(jQuery);